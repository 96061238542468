@import "~@/erp/styles/variables/variables.scss";



































































































































































































































































































.sage-platform-inventory{
  padding: 20px;
  .inventory-form{
    .el-form-item{
        .el-form-item__content{
          display: flex;
          .tip{
            margin-left: 10px;
            display: flex;
            align-items: center;
            font-size: 14px;
            color: #F59A23;
          }
          .el-input{
            width: 185px;
          }
          .el-button{
            width: 140px;
          }
        }
      }
  }
  .title{
    font-size: 16px;
  }
  .operation-form{
    padding-top: 20px;
    .el-form-item{
      margin-right: 30px;
    }
  }
  .base-table{
    .table-custom{
      .el-table{
        tr{
          th:first-child {
            border-left: 1px solid #ebeef5;
          }
          td:first-child {
            border-left: 1px solid #ebeef5;
          }
        }

      }
    }
  }
}

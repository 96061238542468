@import "~@/erp/styles/variables/variables.scss";




























































.dialog-box{
  .dialog-title{
    font-size: 14px;
    color: rgba(0,0,0,.8);
    display: flex;
    align-items: center;
    i{
      font-size: 24px;
      margin-right: 8px;
    }

  }
}

